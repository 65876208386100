import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

import userStore from './modules/userStore'
import alertStore from './modules/alertStore'

export default new Vuex.Store({
  state: {
    theme: null,
    locale: 'ko',
    isOverlay: false,
    isAlert: {status: false, string: '', reload: false},
    mapCount: null,
    deviceFilterStatus: JSON.stringify({ normal: true, detect: true, offline: true }),
    preDivisionCount: null,
  },
  getters,
  actions,
  mutations,
  modules: {
    userStore,
    alertStore
  }
})

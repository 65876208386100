const keyStr = "50c95E7A3180C9Fd4eD8b85c1831E854";
import CryptoJS from 'crypto-js';

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const encryptString = (str) => {
  let key = btoa(keyStr);
  let iv = CryptoJS.enc.Hex.parse("0000000000000000");
  
  let encrypt = CryptoJS.AES.encrypt(str, CryptoJS.enc.Base64.parse(key), {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    format: CryptoJS.format.OpenSSL,
    iv: iv
  }).toString();

  return encrypt;
}

export const decryptString = (str) => {
  let key = btoa(keyStr);
  let iv = CryptoJS.enc.Hex.parse("0000000000000000");

  let decrypt = CryptoJS.AES.decrypt(str, CryptoJS.enc.Base64.parse(key), {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    format: CryptoJS.format.OpenSSL,
    iv: iv
  });

  return decrypt.toString(CryptoJS.enc.Utf8);
}

// export const deleteDuplicateObject = (objArray, key) => {
//   return objArray.filter((item, i) => {
//       return objArray.findIndex((item2, j) => {
//         return item[key] === item2[key];
//       }) === i;
//   });
// }

export const makeIndexList = (listArray, totalLangth, nowPage, pagingCount, idxName) => {
  let retArray = new Array();
  for(let i = 0; i<listArray.length; i++) {
      let retObject = new Object();
      retObject = listArray[i];
      if(typeof retObject[idxName] === "undefined") {
        retObject[idxName] = totalLangth - ((nowPage - 1) * pagingCount) - i;
      }
      retArray.push(retObject);
  }
  return retArray;
}

export const getDateRangeArray = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  let arr = []
  let dt = new Date()
  
  for(arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
    arr.push(new Date(dt));
  }
  let result = arr.map((v)=>v.toISOString().slice(0,10))
  return result;
}

export const sortArrayObjectByKey = (array, key) => {
  return array.sort(function(a, b) {
    let x = a[key]; let y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

// const userInfo = (userObj) => {
//   userObj.un = decryptString(userObj.un);
//   userObj.ui = decryptString(userObj.ui);
//   userObj.ue = decryptString(userObj.ue);
//   userObj.ur = decryptString(userObj.ur).split("|@|")[2];
//   userObj.ug = decryptString(userObj.ug);
//   userObj.gn = decryptString(userObj.gn);
//   return userObj
// }
import Vue from 'vue'

Vue.filter('byteToString', (bytes) => {
  if(bytes == null) bytes = 0;
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = 3;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return  parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
});

Vue.filter('secToMin', (sec) => {
  const hour = parseInt(sec/3600);
  const minute = parseInt((sec%3600)/60);
  const second = sec%60;

  return hour + ":" + minute + ":" + second
})

Vue.filter('phoneNumber', (phoneNumber) => {
  let formatNum = "";
  if (phoneNumber.length == 11) {
    formatNum = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } 
  else if (phoneNumber.length == 8) {
    formatNum = phoneNumber.replace(/(\d{4})(\d{4})/, "$1-$2");
  } 
  else {
    if (phoneNumber.indexOf("02") == 0) {
      if (phoneNumber.length == 9) {
        formatNum = phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
      }
      else {
        formatNum = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    }
    else {
      formatNum = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  }
  return formatNum;
});

Vue.filter("makeComma", (val)  =>{
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

Vue.filter("stringPointer", (target, search) => {
  return target.replace(search, "<span class='font-bold text-red-800'" + search + "</span>");
})
export const setTheme = (state, theme) => {
  state.theme = theme
  localStorage.setItem('theme', state.theme)
}

export const setLocale = (state, locale) => {
  state.locale = locale
  localStorage.setItem('locale', locale)
}

export const setIsOverlay = (state, isOverlay) => {
  state.isOverlay = isOverlay
}

export const setIsAlert = (state, isAlert) => {
  state.isAlert = isAlert
}

export const setMapCount = (state, mapCount) => {
  state.mapCount = mapCount
  sessionStorage.setItem('mapCount', state.mapCount)
}

export const setDeviceFilterStatus = (state, deviceFilterStatus) => {
  state.deviceFilterStatus = deviceFilterStatus
  sessionStorage.setItem('deviceFilter', state.deviceFilterStatus)
}

export const setPreDivisionCount = (state, preDivisionCount) => {
  state.preDivisionCount = preDivisionCount
  sessionStorage.setItem('preDivisionCount', state.preDivisionCount)
}

<template lang="html">
  <div id="app">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$Progress.finish()
  },
  created () {
    console.log("Web Start", process.env.VUE_APP_API_PORT)
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      console.log(to, from)
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    // this.$router.afterEach((to, from) => {
    this.$router.afterEach(() => {
      this.$Progress.finish()
    })
  }
  
}
</script>

<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
  height: 100vh;
}
</style>
export const getTheme = (state) => {
  state.theme = state.theme || 'smcom'
  return state.theme
}

export const getLocale = (state) => {
  state.locale = state.locale || 'ko'
  return state.locale
}

export const getIsOverlay = (state) => {
  state.isOverlay = state.isOverlay || false
  return state.isOverlay
}

export const getIsAlert = (state) => {
  state.isAlert = state.isAlert || {status: false, string: ''}
  return state.isAlert
}

export const getMapCount = (state) => {
  state.mapCount = state.mapCount || (sessionStorage.getItem('mapCount') || 1)
  return state.mapCount
}

export const getDeviceFilterStatus = (state) => {
  state.deviceFilterStatus = state.deviceFilterStatus || (sessionStorage.getItem('deviceFilter') || JSON.stringify({ normal: true, detect: true, offline: true }))
  return state.deviceFilterStatus
}

export const getPreDivisionCount = (state) => {
  state.preDivisionCount = state.preDivisionCount || (sessionStorage.getItem('preDivisionCount') || 2)
  return state.preDivisionCount
}

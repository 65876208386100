import Vue from 'vue'
import VueRouter from 'vue-router'
import { decryptString } from "@/utils/config"

Vue.use(VueRouter)

const loadView = (path, view) => {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/${path}/${view}`)
}

const requireAuth = () => (to, from, next) => {
  let authValidate = false;
  if (sessionStorage.getItem('user')) {
    let userInfo = JSON.parse(sessionStorage.getItem('user'))
    if(userInfo.at && userInfo.ur) {
      let userRoleDe = userInfo.ur.userRole;
      let userRole = decryptString(userRoleDe).split("|@|")[2]
      if(to.meta.roles.indexOf(userRole) === -1) {
        alert("권한이 없습니다.")
        return
      }
      else authValidate = true;
    }
  }

  // if (sessionStorage.getItem('user') && sessionStorage.getItem('user').at) {
  //   console.log(sessionStorage.getItem('user').at)
  //   let userRoleDe = sessionStorage.getItem('user').ur.userRole;
  //   let userRole = decryptString(userRoleDe).split("|@|")[2]
  //   console.log(to, from, userRole, userRoleDe)
  //   authValidate = true;
  // }
  if (authValidate) return next()
  next('/login')
}

const requireNotAuth = () => (to, from, next) => {
  if (sessionStorage.getItem('user') != null) next('/main')
  else return next()
}

const settingChild = [
  { path: 'default', name: 'DefaultSetting', component: loadView('pages','setting/Default'), beforeEnter: requireAuth(), meta: {title : 'setting', group : 'settingPage', roles: ['master'] } },
  { path: 'companylist', name: 'CompanyList', component: loadView('pages','setting/CompanyList'), beforeEnter: requireAuth(), meta: {title : 'companylist', group : 'settingCompany', roles: ['master'] } },
  { path: 'companyregist', name: 'CompanyRegist', component: loadView('pages','setting/CompanyRegist'), beforeEnter: requireAuth(), meta: {title : 'companyregist', group : 'settingCompany', roles: ['master'] } },
  { path: 'companyupdate', name: 'CompanyUpdate', component: loadView('pages','setting/CompanyUpdate'), beforeEnter: requireAuth(), meta: {title : 'companyupdate', group : 'settingCompany', roles: ['master'] } },

  { path: 'deviceadmin', name: 'DeviceAdmin', component: loadView('pages','setting/DeviceAdmin'), beforeEnter: requireAuth(), meta: {title : 'deviceadmin', group : 'settingDevice', roles: ['master','admin','member','agent'] } },
  { path: 'deviceregist', name: 'DeviceRegist', component: loadView('pages','setting/DeviceRegist'), beforeEnter: requireAuth(), meta: {title : 'deviceregist', group : 'settingDevice', roles: ['master'] } },
  { path: 'deviceupdate', name: 'DeviceUpdate', component: loadView('pages','setting/DeviceUpdate'), beforeEnter: requireAuth(), meta: {title : 'deviceupdate', group : 'settingDevice', roles: ['master'] } },

  { path: 'mapadmin', name: 'MapAdmin', component: loadView('pages','setting/MapAdmin'), beforeEnter: requireAuth(), meta: {title : 'mapadmin', group : 'settingMap', roles: ['admin'] } },

  { path: 'managerlist', name: 'ManagerList', component: loadView('pages','setting/ManagerList'), beforeEnter: requireAuth(), meta: {title : 'managerlist', group : 'settingManager', roles: ['admin'] } },
  { path: 'managerregist', name: 'ManagerRegist', component: loadView('pages','setting/ManagerRegist'), beforeEnter: requireAuth(), meta: {title : 'managerregist', group : 'settingManager', roles: ['admin'] } },
  { path: 'managerupdate', name: 'ManagerUpdate', component: loadView('pages','setting/ManagerUpdate'), beforeEnter: requireAuth(), meta: {title : 'managerupdate', group : 'settingManager', roles: ['admin'] } },

  { path: 'siteadmin', name: 'SiteAdmin', component: loadView('pages','setting/SiteAdmin'), beforeEnter: requireAuth(), meta: {title : 'siteadmin', group : 'settingSite', roles: ['admin'] } },
  { path: 'useradmin', name: 'UserAdmin', component: loadView('pages','setting/UserAdmin'), beforeEnter: requireAuth(), meta: {title : 'useradmin', group : 'settingUser', roles: ['master','agent','admin','member'] } }
]

const deviceChild = [
  { path: 'default', name: 'DefaultDevice', component: loadView('pages','device/Default'), beforeEnter: requireAuth(), query: {name:'device'}, meta: {title : 'device', group : 'devicePage', roles: ['master','agent','admin','member'] } },
]

const dashboardChild = [
  { path: 'default', name: 'DefaultDashboard', component: loadView('pages','dashboard/Default'), beforeEnter: requireAuth(), query: {name:'dashboard'}, meta: {title : 'dashboard', group : 'dashboardPage', roles: ['master','agent','admin','member'] } },
]

const homeChild = [
  { path: 'default', name: 'DefaultHome', component: loadView('pages','home/Default'), beforeEnter: requireAuth(), query: {name:'home'}, meta: {title : 'home', group : 'homePage', roles: ['master','agent','admin','member'] } },
]

const eventChild = [
  { path: 'default', name: 'DefaultEvent', component: loadView('pages','event/EventAdmin'), beforeEnter: requireAuth(), query: {name:'event'}, meta: {title : 'event', group : 'eventPage', roles: ['master','agent','admin','member'] } },
]

const mainChild = [
  { path: 'home', name: 'HomePage', component: loadView('pages','home/Index'), children: homeChild, redirect: '/main/home/default' },
  { path: 'dashboard', name: 'DashboardPage', component: loadView('pages','dashboard/Index'), children: dashboardChild, redirect: '/main/dashboard/default' },
  { path: 'device', name: 'DevicePage', component: loadView('pages','device/Index'), children: deviceChild, redirect: '/main/device/default' },
  { path: 'setting', name: 'SettingPage', component: loadView('pages','setting/Index'), children: settingChild, redirect: '/main/setting/default' },
  { path: 'event', name: 'EventPage', component: loadView('pages','event/Index'), children: eventChild, redirect: '/main/event/default' },
]

const routes = [
  { path: '/', name: 'IndexPage', component: loadView('views','IndexPage') },
  { path: '/main', name: 'MainPage', component: loadView('views','MainPage'), children: mainChild, redirect: '/main/home' },
  { path: '/login', name: 'LoginPage', component: loadView('views','LoginPage'), beforeEnter: requireNotAuth() },
  { path: '/sso', name: 'SSOPage', component: loadView('views','SSOPage') },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes
})

export default router

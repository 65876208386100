import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/** axios config **/
import axios from 'axios'
Vue.prototype.$http = axios
/** axios config **/

/** Font awesome **/
import { fsLibrary } from '@/references/fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fsLibrary)
Vue.component('font-awesome-icon', FontAwesomeIcon)
/** Font awesome **/

/** tailwind **/
import '@/assets/css/tailwind.css'
import '@/assets/css/main.css'


import VueTailwind from 'vue-tailwind'
import { settings } from '@/references/tailwind'

Vue.use(VueTailwind, settings)
/** tailwind **/

/** progres bar **/
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: 'green',
  failedColor: 'red',
  location: 'top',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 1000
  },
  autoRevert: true,
  inverse: false,
})
/** progres bar **/

/** perfect scrollbar **/
import vuePerfectScrollbar from 'vue-perfect-scrollbar'
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);
/** perfect scrollbar **/

/** column resizable **/
import VueColumnsResizable from 'vue-columns-resizable';
Vue.use(VueColumnsResizable);

/** vue progress path**/
import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'

Vue.use(VueProgress, {})

/** vue moment **/
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, { moment, })

/** vue select **/
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', ''),
  },
});
Vue.component('v-select', vSelect)

/** vue toggle **/
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

/** pinch Zoom **/
import PinchZoom from 'vue-pinch-zoom';
Vue.component('pinch-zoom', PinchZoom);

/** sweet alert **/
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

/** v-drag **/
import VDragged from 'v-dragged'
Vue.use(VDragged) 

/** tree vue **/
import VJstree from 'vue-jstree'
Vue.use(VJstree)

/** vue audio **/
import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

/** vue fullscreen **/
import VueFullscreen from 'vue-fullscreen'
Vue.use(VueFullscreen)

/** vuelidate **/
// import Vuelidate from "vuelidate"
// Vue.use(Vuelidate)

/** vue crontab **/
import VueCrontab from 'vue-crontab'
Vue.use(VueCrontab)


Vue.config.productionTip = false

/** filter **/
import "@/filters"

/** directive  **/
import "@/directives"

/** plugins **/
// import htmlToPdf from '@/plugins/html2pdf';
// Vue.use(htmlToPdf);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
import { 
  faBars, 
  faTools, 
  faChartArea, 
  faChartBar, 
  faChartLine, 
  faChartPie,
  faAsterisk,
  faObjectGroup,
  faFileContract,
  faUser,
  faUsers,
  faAngleLeft,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faVolumeUp,
  faVideo,
  faNetworkWired,
  faBullhorn,
  faCodeBranch,
  faPlayCircle,
  faPauseCircle,
  faBell,
  faKey,
  faPen,
  faTrash,
  faPlusCircle,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'


export const fsLibrary = {
  faBars,
  faTools,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faAsterisk,
  faObjectGroup,
  faFileContract,
  faUser,
  faUsers,
  faAngleLeft,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faVolumeUp,
  faVideo,
  faNetworkWired,
  faBullhorn,
  faCodeBranch,
  faPlayCircle,
  faPauseCircle,
  faBell,
  faKey,
  faPen,
  faTrash,
  faPlusCircle,
  faSortDown,
  faSortUp,
}

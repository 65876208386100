export const chgTheme = ({ commit }, theme) => {
  commit('setTheme', theme)
}

export const chgLocale = ({ commit }, locale) => {
  commit('setLocale', locale)
}

export const chgIsOverlay = ({ commit }, isOverlay) => {
  commit('setIsOverlay', isOverlay)
}

export const chgIsAlert = ({ commit }, isAlert) => {
  commit('setIsAlert', isAlert)
}

export const chgMapCount = ({ commit }, mapCount) => {
  commit('setMapCount', mapCount)
}

export const chgDeviceFilterStatus = ({ commit }, deviceFilterStatus) => {
  commit('setDeviceFilterStatus', deviceFilterStatus)
}

export const chgPreDivisionCount = ({ commit }, preDivisionCount) => {
  commit('setPreDivisionCount', preDivisionCount)
}

const state = {
  currentUser: null,
  userRole: 'member',
  companyGuid: '0'
}
const getters = {
  getCurrentUser: state => {
    state.currentUser = state.currentUser || JSON.parse(sessionStorage.getItem('user'))
    return state.currentUser;
  },
  getUserRole: state => {
    state.userRole = state.userRole || "member"
    return state.userRole
  },
  getCompanyGuid: state => {
    state.companyGuid = state.companyGuid || JSON.parse(sessionStorage.getItem('user')).ur.companyGuid
    return state.companyGuid
  }
}
const mutations = {
  setCurrentUser(state, payload) {
    state.currentUser = payload
    if(sessionStorage.getItem('user') == null) sessionStorage.setItem('user', JSON.stringify(state.currentUser))
  },
  setUserLogout(state) {
    state.currentUser = null
  },
  setUserRole(state, userRole) {
    state.userRole = userRole
  },
  setCompanyGuid(state, companyGuid) {
    state.companyGuid = companyGuid
  }
}
const actions = {
  chgCurrentUser({ commit }, payload) {
    commit('setCurrentUser', payload)
  },
  userLogoutAction({ commit }) {
    sessionStorage.removeItem('user')
    commit('setUserLogout')
  },
  chgUserRole({ commit }, userRole) {
    commit('setUserRole', userRole)
  },
  chgCompanyGuid({ commit }, companyGuid) {
    commit('setCompanyGuid', companyGuid)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
const state = {
  isShowEvent: false,
  activeEventList: [],
  lastSocketTime: 0,
  alarmCount: {detect:0, offline:0},
  eventTimeStamp: null,
  onlineDevice: '',
  offlineDevice: '',
}
const getters = {
  getIsShowEvent (state) {
    return state.isShowEvent
  },
  getActiveEventList (state) {
    return state.activeEventList
  },
  getLastSocketTime (state) {
    return state.lastSocketTime
  },
  getAlarmCount (state) {
    return state.alarmCount
  },
  getEventTimestamp (state) {
    return state.eventTimeStamp || new Date().getTime();
  },
  getOnlineDevice (state) {
    return state.onlineDevice
  },
  getOfflineDevice (state) {
    return state.offlineDevice
  }
}
const mutations = {
  setIsShowEvent (state, isShowEvent) {
    state.isShowEvent = isShowEvent
  },
  setActiveEventList (state, activeEventList) {
    state.activeEventList = activeEventList
  },
  setLastSocketTime (state, lastSocketTime) {
    state.lastSocketTime = lastSocketTime
  },
  setAlarmCount (state, alarmCount) {
    state.alarmCount = alarmCount
  },
  setEventTimestamp (state, eventTimeStamp) {
    state.eventTimeStamp = eventTimeStamp
  },
  setOnlineDevice (state, onlineDevice) {
    state.onlineDevice = onlineDevice
  },
  setOfflineDevice (state, offlineDevice) {
    state.offlineDevice = offlineDevice
  }
}
const actions = {
  chgIsShowEvent ({ commit }, isShowEvent) {
    commit('setIsShowEvent', isShowEvent)
  },
  chgActiveEventList ({ commit }, activeEventList) {
    commit('setActiveEventList', activeEventList)
  },
  chgLastSocketTime ({ commit }, lastSocketTime) {
    commit('setLastSocketTime', lastSocketTime)
  },
  chgAlarmCount ({ commit }, alarmCount) {
    commit('setAlarmCount', alarmCount)
  },
  chgEventTimestamp ({ commit }, eventTimeStamp) {
    commit('setEventTimestamp', eventTimeStamp)
  },
  chgOnlineDevice ({ commit }, onlineDevice) {
    commit('setOnlineDevice', onlineDevice)
  },
  chgOfflineDevice ({ commit }, offlineDevice) {
    commit('setOfflineDevice', offlineDevice)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
